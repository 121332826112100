var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import "./wdyr";
import React, { Suspense, useEffect, lazy } from "react";
import { HashRouter, BrowserRouter, Route, Switch, Redirect, useHistory, } from "react-router-dom";
import { isElectron } from "electron-platform";
import { ThemeProvider } from "styled-components";
import { MeetingProvider, NotificationProvider, } from "amazon-chime-sdk-component-library-react";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { AppStateProvider, useAppState } from "./providers/AppStateProvider";
import { NavigationProvider } from "./providers/NavigationProvider";
import Notifications from "./components/Notifications";
import NoMeetingRedirect from "./components/NoMeetingRedirect";
import routes from "./contants/route";
import ErrorProvider from "./providers/ErrorProvider";
import meetingConfig from "./meetingConfig";
import { parseJwt } from "./util/auth";
import { darkTheme, GlobalStyles, lightTheme } from "./Theme";
import { SocketProvider } from "./providers/SocketProvider";
import Loader from "./components/Loader";
import "./App.css";
import materialTheme from "./variables/materialTheme";
import { MediaProvider } from "./providers/MediaShareProvider";
import { DeviceUUID } from "device-uuid";
import { LOADED } from "./variables/constants";
import { CounterProvider } from "./providers/CounterProvider";
import { ActiveSpeakerProvider } from "./providers/ActiveSpeakerProvider";
var WaitingForMeeting = lazy(function () { return import("./pages/WaitingForMeeting"); });
var MeetingNotStarted = lazy(function () { return import("./pages/MeetingNotStarted"); });
var JoinedFromAnotherDevice = lazy(function () { return import("./pages/JoinedFromAnotherDevice"); });
var EndMeeting = lazy(function () { return import("./pages/EndMeeting"); });
var NotFound = lazy(function () { return import("./pages/NotFound"); });
var Host = lazy(function () { return import("./pages/Host"); });
var Connect = lazy(function () { return import("./pages/Connect"); });
var DeviceSetup = lazy(function () { return import("./containers/DeviceSetup"); });
var Meeting = lazy(function () { return import("./containers/Meeting"); });
var Recital = lazy(function () { return import("./pages/Recital"); });
var AppRouter = function (props) {
    var history = useHistory();
    var setJwtToken = useAppState().setJwtToken;
    var params = new URLSearchParams(window.location.search);
    var token = params.get("token");
    useEffect(function () {
        if (token) {
            var jwt = parseJwt(token);
            setJwtToken(jwt);
        }
        if (isElectron) {
            var ipcRenderer = require("electron").ipcRenderer;
            ipcRenderer.on("url-change", function (_, data) {
                if (data === null || data === void 0 ? void 0 : data.token) {
                    var params_1 = new URLSearchParams();
                    params_1.append("token", data === null || data === void 0 ? void 0 : data.token);
                    history.push("connect?" + params_1.toString());
                }
            });
        }
    }, []);
    return (React.createElement(Suspense, { fallback: React.createElement(Loader, { open: true }) },
        React.createElement(Switch, null,
            React.createElement(Route, { path: routes.HOST, component: Host }),
            React.createElement(Route, { path: routes.HOME, component: Connect }),
            React.createElement(Route, { path: routes.DEVICE },
                React.createElement(NoMeetingRedirect, null,
                    React.createElement(DeviceSetup, null))),
            React.createElement(Route, { path: routes.MEETING },
                React.createElement(SocketProvider, null,
                    React.createElement(NoMeetingRedirect, null,
                        React.createElement(CounterProvider, null,
                            React.createElement(ActiveSpeakerProvider, null,
                                React.createElement(Meeting, null)))))),
            React.createElement(Route, { path: routes.NOT_FOUND, component: NotFound }),
            React.createElement(Route, { path: routes.WAITING_FOR_MEETING, component: WaitingForMeeting }),
            React.createElement(Route, { exact: true, path: "/", render: props === null || props === void 0 ? void 0 : props.render }),
            React.createElement(Route, { path: routes.MEETING_END, component: EndMeeting }),
            React.createElement(Route, { path: routes.MEETING_NOT_STARTED, component: MeetingNotStarted }),
            React.createElement(Route, { path: routes.JOINED_FROM_OTHER_DEVICE, component: JoinedFromAnotherDevice }),
            React.createElement(Route, { path: routes.RECITAL },
                React.createElement(SocketProvider, null,
                    React.createElement(NoMeetingRedirect, null,
                        React.createElement(CounterProvider, null,
                            React.createElement(Recital, null))))))));
};
var AppContainer = function () {
    var defaultRoute = function () {
        var params = new URLSearchParams(window.location.search);
        var token = params.get("token");
        if (token) {
            if (!isElectron) {
                var jwt = parseJwt(token);
                if (jwt === null || jwt === void 0 ? void 0 : jwt.is_host) {
                    return "/host?token=" + token;
                }
            }
            else {
                return "/connect?token=" + token;
            }
            var loaded = params.get(LOADED);
            var uuid = new DeviceUUID().get();
            if (loaded === uuid) {
                return "/connect?token=" + token + "&" + LOADED + "=" + uuid;
            }
            else {
                return "/connect?token=" + token;
            }
        }
        if (isElectron) {
            return routes.WAITING_FOR_MEETING;
        }
        return routes.NOT_FOUND;
    };
    var route = defaultRoute();
    return (React.createElement(AppStateProvider, null,
        React.createElement(Theme, null,
            React.createElement(NotificationProvider, null,
                React.createElement(Notifications, null),
                React.createElement(ErrorProvider, null,
                    React.createElement(MeetingProvider, __assign({}, meetingConfig),
                        React.createElement(NavigationProvider, null,
                            React.createElement(MediaProvider, null,
                                React.createElement(AppRouter, { render: function () {
                                        return React.createElement(Redirect, { to: route });
                                    } })))))))));
};
var App = function () {
    if (isElectron) {
        return (React.createElement(HashRouter, null,
            React.createElement(AppContainer, null)));
    }
    return (React.createElement(BrowserRouter, null,
        React.createElement(AppContainer, null)));
};
var Theme = function (_a) {
    var children = _a.children;
    var _b = useAppState(), toggleTheme = _b.toggleTheme, theme = _b.theme, hostInfo = _b.hostInfo, setmeetingType = _b.setmeetingType, sethostInfo = _b.sethostInfo;
    var params = new URLSearchParams(window.location.search);
    var token = params.get("token");
    var jwt = null;
    var themeData = null;
    if (!hostInfo && token) {
        jwt = parseJwt(token);
        if ((jwt === null || jwt === void 0 ? void 0 : jwt.type) && (jwt === null || jwt === void 0 ? void 0 : jwt.type) === "recital") {
            setmeetingType("recital");
            if (jwt === null || jwt === void 0 ? void 0 : jwt.is_host) {
                sethostInfo(__assign({}, { role: "moderator" }));
            }
            else {
                sethostInfo(__assign({}, { role: "audience" }));
            }
        }
        else {
            if (jwt === null || jwt === void 0 ? void 0 : jwt.is_host) {
                sethostInfo(__assign({}, { role: "instructor" }));
            }
            else {
                sethostInfo(__assign({}, { role: "attendee" }));
            }
        }
        if (jwt === null || jwt === void 0 ? void 0 : jwt.theme) {
            toggleTheme(jwt === null || jwt === void 0 ? void 0 : jwt.theme);
            themeData = jwt === null || jwt === void 0 ? void 0 : jwt.theme;
        }
    }
    else {
        themeData = theme;
    }
    return (React.createElement(StylesProvider, { injectFirst: true },
        React.createElement(MuiThemeProvider, { theme: materialTheme },
            React.createElement(ThemeProvider, { theme: themeData === "light" ? lightTheme : darkTheme },
                React.createElement(GlobalStyles, null),
                children))));
};
export default App;
