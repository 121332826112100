"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHARE_WINDOWS_AUDIO = exports.DESKTOP_MAIN_WINDOW = exports.MUSIC_KIT_WINDOW = exports.MUSIC_KIT_WINDOW_IPC = exports.COMMANDS = exports.Role = void 0;
var Role;
(function (Role) {
    Role["Host"] = "host";
    Role["Attendee"] = "attendee";
})(Role = exports.Role || (exports.Role = {}));
var COMMANDS;
(function (COMMANDS) {
    COMMANDS["SIGNUP"] = "SIGN_UP";
    COMMANDS["SIGN_OUT"] = "SIGN_OUT";
    COMMANDS["IS_AUTHORIZED"] = "IS_AUTHORIZED";
    COMMANDS["PLAY"] = "PLAY";
    COMMANDS["PAUSE"] = "PAUSE";
    COMMANDS["PLAYLIST"] = "PLAYLIST";
    COMMANDS["PLAYLIST_PLAY"] = "PLAYLIST_PLAY";
    COMMANDS["PLAYER"] = "PLAYER";
    COMMANDS["INITIALIZED"] = "INITIALIZED";
    COMMANDS["ATTRIBUTES"] = "ATTRIBUTES";
    COMMANDS["NEXT_SONG"] = "NEXT_SONG";
    COMMANDS["PREVIOUS_SONG"] = "PREVIOUS_SONG";
    COMMANDS["SEEK_TO_TIME"] = "SEEK_TO_TIME";
    COMMANDS["VOLUME"] = "VOLUME";
})(COMMANDS = exports.COMMANDS || (exports.COMMANDS = {}));
exports.MUSIC_KIT_WINDOW_IPC = "music-kit-window-ipc";
exports.MUSIC_KIT_WINDOW = "music-kit-window";
exports.DESKTOP_MAIN_WINDOW = "desktop-main-window";
exports.SHARE_WINDOWS_AUDIO = "share-windows-audio";
