var ActiveSpeakerPolicy = (function () {
    function ActiveSpeakerPolicy(speakerWeight, cutoffThreshold, silenceThreshold, takeoverRate, callback) {
        if (speakerWeight === void 0) { speakerWeight = 0.9; }
        if (cutoffThreshold === void 0) { cutoffThreshold = 0.1; }
        if (silenceThreshold === void 0) { silenceThreshold = 0.2; }
        if (takeoverRate === void 0) { takeoverRate = 0.2; }
        this.speakerWeight = speakerWeight;
        this.cutoffThreshold = cutoffThreshold;
        this.silenceThreshold = silenceThreshold;
        this.takeoverRate = takeoverRate;
        this.callback = callback;
        this.volumes = {};
    }
    ActiveSpeakerPolicy.prototype.calculateScore = function (attendeeId, volume, muted) {
        var _a;
        var _b;
        (_b = this === null || this === void 0 ? void 0 : this.callback) === null || _b === void 0 ? void 0 : _b.call(this, (_a = {}, _a[attendeeId] = { volume: volume, muted: Boolean(muted) }, _a));
        if (!this.volumes.hasOwnProperty(attendeeId)) {
            this.volumes[attendeeId] = 0;
        }
        if (muted || volume === null) {
            this.volumes[attendeeId] = 0;
        }
        else {
            volume = volume > this.silenceThreshold ? 1.0 : 0.0;
            var score = this.volumes[attendeeId] * this.speakerWeight + volume * (1 - this.speakerWeight);
            for (var otherAttendeeId in this.volumes) {
                if (otherAttendeeId !== attendeeId) {
                    this.volumes[otherAttendeeId] = Math.max(this.volumes[otherAttendeeId] - this.takeoverRate * volume, 0);
                }
            }
            this.volumes[attendeeId] = score;
        }
        return this.volumes[attendeeId];
    };
    ActiveSpeakerPolicy.prototype.prioritizeVideoSendBandwidthForActiveSpeaker = function () {
        return true;
    };
    return ActiveSpeakerPolicy;
}());
export default ActiveSpeakerPolicy;
