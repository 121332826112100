import { createTheme } from "@material-ui/core";
var materialTheme = createTheme({
    palette: {
        primary: {
            main: "#21D0B2",
        },
        secondary: {
            main: "#3F5261",
        },
        background: {
            default: "#fff",
        },
        error: {
            main: "#E0284A",
        },
        grey: {
            100: "#EFF7FD",
            200: "#D9E6F0",
            300: "#B4C3D0"
        },
    },
    spacing: [4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 72],
    typography: {
        fontFamily: [
            "Poppins",
        ].join(","),
    },
});
export default materialTheme;
