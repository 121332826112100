import React, { useState, useContext, useEffect, useRef, useMemo, } from "react";
import { useLocation } from "react-router-dom";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import routes from "../contants/route";
import { isDesktop } from "../util/comman";
var NavigationContext = React.createContext(null);
var NavigationProvider = function (_a) {
    var children = _a.children;
    var _b = useState(isDesktop), showNavbar = _b[0], setShowNavbar = _b[1];
    var _c = useState(isDesktop), showRoster = _c[0], setShowRoster = _c[1];
    var _d = useState(false), showMetrics = _d[0], setShowMetrics = _d[1];
    var isDesktopView = useRef(isDesktop());
    var location = useLocation();
    var meetingManager = useMeetingManager();
    useEffect(function () {
        if (location.pathname.includes(routes.MEETING)) {
            return function () {
                meetingManager.leave();
            };
        }
    }, [location.pathname]);
    useEffect(function () {
        var handler = function () {
            var isResizeDesktop = isDesktop();
            if (isDesktopView.current === isResizeDesktop) {
                return;
            }
            isDesktopView.current = isResizeDesktop;
            if (!isResizeDesktop) {
                setShowNavbar(false);
                setShowRoster(false);
            }
            else {
                setShowNavbar(true);
            }
        };
        window.addEventListener("resize", handler);
        return function () { return window.removeEventListener("resize", handler); };
    }, []);
    var toggleRoster = function () {
        setShowRoster(!showRoster);
    };
    var toggleNavbar = function () {
        setShowNavbar(!showNavbar);
    };
    var toggleMetrics = function () {
        setShowMetrics(function (currentState) { return !currentState; });
    };
    var openNavbar = function () {
        setShowNavbar(true);
    };
    var closeNavbar = function () {
        setShowNavbar(false);
    };
    var openRoster = function () {
        setShowRoster(true);
    };
    var closeRoster = function () {
        setShowRoster(false);
    };
    var providerValue = useMemo(function () { return ({
        isDesktopView: isDesktopView,
        showNavbar: showNavbar,
        showRoster: showRoster,
        showMetrics: showMetrics,
        toggleRoster: toggleRoster,
        toggleNavbar: toggleNavbar,
        toggleMetrics: toggleMetrics,
        openRoster: openRoster,
        closeRoster: closeRoster,
        openNavbar: openNavbar,
        closeNavbar: closeNavbar,
    }); }, [
        isDesktopView,
        showNavbar,
        showRoster,
        showMetrics,
        toggleRoster,
        toggleNavbar,
        toggleMetrics,
        openRoster,
        closeRoster,
        openNavbar,
        closeNavbar,
    ]);
    return (React.createElement(NavigationContext.Provider, { value: providerValue }, children));
};
var useNavigation = function () {
    var context = useContext(NavigationContext);
    if (!context) {
        throw Error("Use useNavigation in NavigationProvider");
    }
    return context;
};
export { NavigationProvider, useNavigation };
