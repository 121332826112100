export var DevicePermissionStatus;
(function (DevicePermissionStatus) {
    DevicePermissionStatus["UNSET"] = "UNSET";
    DevicePermissionStatus["IN_PROGRESS"] = "IN_PROGRESS";
    DevicePermissionStatus["GRANTED"] = "GRANTED";
    DevicePermissionStatus["DENIED"] = "DENIED";
})(DevicePermissionStatus || (DevicePermissionStatus = {}));
export var DeviceLabels;
(function (DeviceLabels) {
    DeviceLabels[DeviceLabels["None"] = 0] = "None";
    DeviceLabels[DeviceLabels["Audio"] = 1] = "Audio";
    DeviceLabels[DeviceLabels["Video"] = 2] = "Video";
    DeviceLabels[DeviceLabels["AudioAndVideo"] = 3] = "AudioAndVideo";
})(DeviceLabels || (DeviceLabels = {}));
export var ViewEnum;
(function (ViewEnum) {
    ViewEnum["Gallery"] = "gallery";
    ViewEnum["Spotlight"] = "spotlight";
    ViewEnum["Group"] = "group";
})(ViewEnum || (ViewEnum = {}));
export var Socket;
(function (Socket) {
    Socket["VIEW"] = "view";
    Socket["GROUP"] = "group";
    Socket["SHARE_SCREEN"] = "screenshare";
    Socket["MUTE_LOCAL_AUDIO"] = "muteLocalAudio";
    Socket["MUTE"] = "mute";
    Socket["MUTE_USER"] = "muteuser";
    Socket["REMOVED"] = "removed";
})(Socket || (Socket = {}));
