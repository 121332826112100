var awsPath = "/Prod";
export var rootPath = window.location.href.includes(awsPath)
    ? awsPath + "/"
    : "/";
var routes = {
    HOME: rootPath + "connect",
    DEVICE: rootPath + "devices",
    MEETING: rootPath + "meeting",
    HOST: rootPath + "host",
    NOT_FOUND: rootPath + "not-found",
    WAITING_FOR_MEETING: rootPath + "waiting-for-meeting",
    APPLE_MUSIC: rootPath + "apple-music",
    MEETING_END: rootPath + "meeting-end",
    MEETING_NOT_STARTED: rootPath + "meeting-not-started",
    JOINED_FROM_OTHER_DEVICE: rootPath + "joined-from-another-device",
    RECITAL: rootPath + "recital",
};
export default routes;
