import { DefaultDeviceController } from "amazon-chime-sdk-js";
export var getUrlParams = function () {
    var result = {};
    var params = (window.location.search.split("?")[1] || "").split("&");
    for (var param in params) {
        if (params.hasOwnProperty(param)) {
            var paramParts = params[param].split("=");
            result[paramParts[0]] = decodeURIComponent(paramParts[1] || "");
        }
    }
    return result;
};
export var isDesktop = function () { return window.innerWidth > 1000; };
export var isOptionActive = function (meetingManagerDeviceId, currentDeviceId) {
    if (currentDeviceId === "none" && meetingManagerDeviceId === null) {
        return true;
    }
    return currentDeviceId === meetingManagerDeviceId;
};
export var supportsSetSinkId = function () {
    return "setSinkId" in HTMLAudioElement.prototype;
};
export var videoInputSelectionToDevice = function (deviceId) {
    if (deviceId === "blue") {
        return DefaultDeviceController.synthesizeVideoDevice("blue");
    }
    if (deviceId === "smpte") {
        return DefaultDeviceController.synthesizeVideoDevice("smpte");
    }
    if (deviceId === "none") {
        return null;
    }
    return deviceId;
};
export var noop = function () { };
export var getRange = function (start, stop) {
    return Array.from(new Array(stop - start + 1), function (_, i) { return i + start; });
};
