import React, { useState, useEffect, useContext, useMemo, } from "react";
var context = React.createContext(null);
export function getCounterContext() {
    return context;
}
var CounterProvider = function (_a) {
    var children = _a.children;
    var CounterContext = getCounterContext();
    var _b = useState("00"), second = _b[0], setSecond = _b[1];
    var _c = useState("00"), minute = _c[0], setMinute = _c[1];
    var _d = useState(0), counter = _d[0], setCounter = _d[1];
    useEffect(function () {
        var intervalId = setInterval(function () {
            var secondCounter = counter % 60;
            var minuteCounter = Math.floor(counter / 60);
            var computedSecond = String(secondCounter).length === 1
                ? "0" + secondCounter
                : secondCounter;
            var computedMinute = String(minuteCounter).length === 1
                ? "0" + minuteCounter
                : minuteCounter;
            setSecond(computedSecond);
            setMinute(computedMinute);
            setCounter(function (counter) { return counter + 1; });
        }, 1000);
        return function () { return clearInterval(intervalId); };
    }, [counter]);
    var providerValue = useMemo(function () { return ({
        second: second,
        minute: minute,
    }); }, [second, minute]);
    return (React.createElement(CounterContext.Provider, { value: providerValue }, children));
};
var useCounter = function () {
    var counterContext = useContext(context);
    if (!counterContext) {
        throw Error("Use useCounter in NavigationProvider");
    }
    return counterContext;
};
export { CounterProvider, useCounter };
