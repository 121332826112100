var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useContext, useCallback, useEffect, useRef, useMemo } from "react";
import { ActionType, Severity, useContentShareControls, useContentShareState, useMeetingManager, useNotificationDispatch } from "amazon-chime-sdk-component-library-react";
import { isElectron } from "electron-platform";
import sleep from "../util/sleep";
import { useAppState } from "./AppStateProvider";
import { ContentShareMediaStreamBroker } from "amazon-chime-sdk-js";
import getOS, { Platforms } from "../util/getOS";
import { BLACK_HOLE_AUDIO_MIXER, WINDOWS_DRIVER_MICROPHONE_NAME } from "../variables/constants";
import { SHARE_WINDOWS_AUDIO } from "@sp/types";
var MediaShareContext = React.createContext(null);
var MediaProvider = function (_a) {
    var children = _a.children;
    var meetingManager = useMeetingManager();
    var _b = useState(), musicDeviceId = _b[0], setMusicDeviceId = _b[1];
    var _c = useState(), videoDeviceId = _c[0], setVideoDeviceId = _c[1];
    var _d = useState(null), audioPlayer = _d[0], setAudioPlayer = _d[1];
    var _e = useState(false), isMusicSharing = _e[0], setIsMusicSharing = _e[1];
    var _f = useState(1), volume = _f[0], setVolume = _f[1];
    var audioContextRef = useRef(null);
    var gainNodeRef = useRef(null);
    var onMusicSharedChange = useCallback(function (deviceId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setMusicDeviceId(deviceId);
            return [2];
        });
    }); }, []);
    var onScreenShareChange = useCallback(function (deviceId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setVideoDeviceId(deviceId);
            return [2];
        });
    }); }, []);
    var onVolumeChange = useCallback(function (newVolume) {
        var _a;
        setVolume(newVolume);
        if ((_a = gainNodeRef === null || gainNodeRef === void 0 ? void 0 : gainNodeRef.current) === null || _a === void 0 ? void 0 : _a.gain) {
            gainNodeRef.current.gain.value = newVolume;
        }
    }, []);
    var changeInstructorAudio = useCallback(function (musicDeviceId, devices, audioStream) { return __awaiter(void 0, void 0, void 0, function () {
        var Sentry, player, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    Sentry = require("@sentry/electron");
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    if (audioPlayer) {
                        audioPlayer.srcObject = null;
                        setAudioPlayer(null);
                    }
                    if (!musicDeviceId) return [3, 4];
                    player = new Audio();
                    player.srcObject = audioStream;
                    return [4, player.setSinkId(musicDeviceId)];
                case 2:
                    _a.sent();
                    return [4, player.play()];
                case 3:
                    _a.sent();
                    setAudioPlayer(player);
                    _a.label = 4;
                case 4: return [3, 6];
                case 5:
                    e_1 = _a.sent();
                    console.log(e_1);
                    Sentry.captureException(e_1);
                    return [3, 6];
                case 6: return [2];
            }
        });
    }); }, []);
    var handleCurrentStream = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var mediaStream, devices, platformOS_1, audDevice, audioStream, audioSource, audioDestination, musicTrack, ipcRenderer, mediaStreamBroker, videoStream, videoStreamTrack;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __generator(this, function (_l) {
            switch (_l.label) {
                case 0:
                    mediaStream = new MediaStream();
                    if (!musicDeviceId) return [3, 6];
                    return [4, navigator.mediaDevices.enumerateDevices()];
                case 1:
                    devices = _l.sent();
                    platformOS_1 = getOS();
                    audDevice = devices.find(function (device) {
                        if (platformOS_1 === Platforms.WINDOWS) {
                            return (device.label.includes(WINDOWS_DRIVER_MICROPHONE_NAME) &&
                                device.kind === "audioinput");
                        }
                        else if (platformOS_1 === Platforms.MAC) {
                            return (device.kind === "audiooutput" &&
                                device.label.includes(BLACK_HOLE_AUDIO_MIXER));
                        }
                    });
                    return [4, navigator.mediaDevices.getUserMedia({
                            audio: { deviceId: audDevice === null || audDevice === void 0 ? void 0 : audDevice.deviceId },
                        })];
                case 2:
                    audioStream = _l.sent();
                    audioContextRef.current = new AudioContext();
                    gainNodeRef.current = audioContextRef.current.createGain();
                    audioSource = audioContextRef.current.createMediaStreamSource(audioStream);
                    audioDestination = audioContextRef.current.createMediaStreamDestination();
                    audioSource.connect(gainNodeRef.current);
                    gainNodeRef.current.connect(audioDestination);
                    gainNodeRef.current.gain.value = volume;
                    return [4, audioContextRef.current.resume()];
                case 3:
                    _l.sent();
                    musicTrack = (_a = audioStream.getAudioTracks()) === null || _a === void 0 ? void 0 : _a[0];
                    (_b = audioDestination.stream) === null || _b === void 0 ? void 0 : _b.addTrack(musicTrack);
                    mediaStream = audioDestination.stream;
                    return [4, changeInstructorAudio(musicDeviceId, devices, mediaStream)];
                case 4:
                    _l.sent();
                    if (!!isMusicSharing) return [3, 6];
                    ipcRenderer = require("electron").ipcRenderer;
                    return [4, ipcRenderer.invoke(SHARE_WINDOWS_AUDIO)];
                case 5:
                    _l.sent();
                    setIsMusicSharing(true);
                    _l.label = 6;
                case 6:
                    if (!videoDeviceId) return [3, 8];
                    mediaStreamBroker = new ContentShareMediaStreamBroker(meetingManager === null || meetingManager === void 0 ? void 0 : meetingManager.logger);
                    return [4, mediaStreamBroker.acquireScreenCaptureDisplayInputStream(videoDeviceId)];
                case 7:
                    videoStream = _l.sent();
                    videoStreamTrack = (_c = videoStream === null || videoStream === void 0 ? void 0 : videoStream.getVideoTracks()) === null || _c === void 0 ? void 0 : _c[0];
                    mediaStream === null || mediaStream === void 0 ? void 0 : mediaStream.addTrack(videoStreamTrack);
                    _l.label = 8;
                case 8:
                    if (!(((_d = mediaStream === null || mediaStream === void 0 ? void 0 : mediaStream.getTracks()) === null || _d === void 0 ? void 0 : _d.length) > 0)) return [3, 12];
                    if (!!((_g = (_f = (_e = meetingManager === null || meetingManager === void 0 ? void 0 : meetingManager.audioVideo) === null || _e === void 0 ? void 0 : _e.audioVidehandleCurrentStreamoController) === null || _f === void 0 ? void 0 : _f.mediaStreamBroker) === null || _g === void 0 ? void 0 : _g.mediaStream)) return [3, 10];
                    (_h = meetingManager === null || meetingManager === void 0 ? void 0 : meetingManager.audioVideo) === null || _h === void 0 ? void 0 : _h.stopContentShare();
                    return [4, sleep(500)];
                case 9:
                    _l.sent();
                    _l.label = 10;
                case 10: return [4, ((_j = meetingManager === null || meetingManager === void 0 ? void 0 : meetingManager.audioVideo) === null || _j === void 0 ? void 0 : _j.startContentShare(mediaStream))];
                case 11:
                    _l.sent();
                    return [3, 13];
                case 12:
                    if (audioPlayer) {
                        audioPlayer.srcObject = null;
                        setAudioPlayer(null);
                    }
                    setIsMusicSharing(false);
                    (_k = meetingManager === null || meetingManager === void 0 ? void 0 : meetingManager.audioVideo) === null || _k === void 0 ? void 0 : _k.stopContentShare();
                    _l.label = 13;
                case 13: return [2];
            }
        });
    }); }, [musicDeviceId, videoDeviceId]);
    useEffect(function () {
        handleCurrentStream();
    }, [musicDeviceId, videoDeviceId]);
    var _g = useState(false), showLoading = _g[0], setShowLoading = _g[1];
    var dispatch = useNotificationDispatch();
    var hostInfo = useAppState().hostInfo;
    var _h = useState(false), isPickerEnabled = _h[0], setIsPickerEnabled = _h[1];
    var _j = useContentShareState(), isLocalUserSharing = _j.isLocalUserSharing, sharingAttendeeId = _j.sharingAttendeeId;
    var toggleContentShare = useContentShareControls().toggleContentShare;
    var shareAudioWithScreen = function (selectedSourceId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, , 4, 5]);
                    if (!selectedSourceId) return [3, 2];
                    setShowLoading(true);
                    onScreenShareChange(selectedSourceId);
                    onPickerClose();
                    return [4, sleep(5000)];
                case 1:
                    _a.sent();
                    return [3, 3];
                case 2:
                    onScreenShareChange(undefined);
                    _a.label = 3;
                case 3: return [3, 5];
                case 4:
                    setShowLoading(false);
                    return [7];
                case 5: return [2];
            }
        });
    }); };
    var onScreenShare = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (sharingAttendeeId && !(sharingAttendeeId === null || sharingAttendeeId === void 0 ? void 0 : sharingAttendeeId.includes((_a = hostInfo === null || hostInfo === void 0 ? void 0 : hostInfo.AttendeeId) !== null && _a !== void 0 ? _a : ""))) {
                        dispatch({
                            type: ActionType.ADD,
                            payload: {
                                severity: Severity.INFO,
                                message: "Someone is already sharing screen!.",
                                autoClose: true,
                                replaceAll: true,
                            },
                        });
                        return [2];
                    }
                    if (!isElectron) return [3, 4];
                    if (!!isLocalUserSharing) return [3, 1];
                    setIsPickerEnabled(true);
                    return [3, 3];
                case 1: return [4, shareAudioWithScreen()];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3: return [2];
                case 4:
                    toggleContentShare();
                    return [2];
            }
        });
    }); };
    var resetShareState = function () {
        setShowLoading(false);
        setIsPickerEnabled(false);
    };
    var onPickerClose = function () {
        setIsPickerEnabled(false);
    };
    var closeSnakbar = function (event, reason) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (reason === "clickaway") {
                        return [2];
                    }
                    if (!isElectron) return [3, 2];
                    return [4, shareAudioWithScreen()];
                case 1:
                    _b.sent();
                    return [3, 3];
                case 2:
                    (_a = meetingManager === null || meetingManager === void 0 ? void 0 : meetingManager.audioVideo) === null || _a === void 0 ? void 0 : _a.stopContentShare();
                    _b.label = 3;
                case 3: return [2];
            }
        });
    }); };
    var providerValue = useMemo(function () { return ({
        isPickerEnabled: isPickerEnabled,
        showLoading: showLoading,
        isMusicSharing: isMusicSharing,
        volume: volume,
        closeSnakbar: closeSnakbar,
        setShowLoading: setShowLoading,
        onPickerClose: onPickerClose,
        shareAudioWithScreen: shareAudioWithScreen,
        setIsPickerEnabled: setIsPickerEnabled,
        resetShareState: resetShareState,
        onScreenShare: onScreenShare,
        onMusicSharedChange: onMusicSharedChange,
        onScreenShareChange: onScreenShareChange,
        onVolumeChange: onVolumeChange,
    }); }, [isPickerEnabled,
        showLoading,
        isMusicSharing,
        volume,
        closeSnakbar,
        setShowLoading,
        onPickerClose,
        shareAudioWithScreen,
        setIsPickerEnabled,
        resetShareState,
        onScreenShare,
        onMusicSharedChange,
        onScreenShareChange,
        onVolumeChange,]);
    return (React.createElement(MediaShareContext.Provider, { value: providerValue }, children));
};
var useMediaShare = function () {
    var context = useContext(MediaShareContext);
    if (!context) {
        throw Error("Use useMediaShare in Provider");
    }
    return context;
};
export { MediaProvider, useMediaShare };
