import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from '@sp/app-shared/lib/esm';
import reportWebVitals from './reportWebVitals';
import ApplicationError from '@sp/app-shared/lib/esm/pages/ApplicationError';
import './core/sentry';
import { ErrorBoundary } from '@sentry/react';

ReactDOM.render(
    <ErrorBoundary fallback={<ApplicationError />} >
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
