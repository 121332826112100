var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ActionType, useNotificationDispatch, useNotificationState, } from "amazon-chime-sdk-component-library-react";
import React from "react";
import styled from "styled-components";
import UiNotification from "./CommonUi/UiNotification";
var StyledNotificationGroup = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var CustomNotificationGroup = function () {
    var notifications = useNotificationState().notifications;
    var dispatch = useNotificationDispatch();
    return (React.createElement(StyledNotificationGroup, null, notifications.map(function (_a) {
        var id = _a.id, severity = _a.severity, message = _a.message, rest = __rest(_a, ["id", "severity", "message"]);
        return (React.createElement(UiNotification, __assign({ key: id }, rest, { severity: severity, message: message, open: true, timeout: 3000, handleClose: function () { return dispatch({ type: ActionType.REMOVE, payload: id }); } })));
    })));
};
export default CustomNotificationGroup;
var templateObject_1;
