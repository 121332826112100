var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useState, useMemo } from "react";
import { ViewEnum, } from "../types";
var AppStateContext = React.createContext(null);
export function useAppState() {
    var state = useContext(AppStateContext);
    if (!state) {
        throw new Error("useAppState must be used within AppStateProvider");
    }
    return state;
}
var query = new URLSearchParams(window.location.search);
export function AppStateProvider(_a) {
    var children = _a.children;
    var _b = useState(query.get("meetingId") || ""), meetingId = _b[0], setMeeting = _b[1];
    var _c = useState(query.get("region") || ""), region = _c[0], setRegion = _c[1];
    var _d = useState(null), meetingInfo = _d[0], setMeetingInfo = _d[1];
    var _e = useState(""), localUserName = _e[0], setLocalName = _e[1];
    var _f = useState("class"), meetingType = _f[0], setmeetingType = _f[1];
    var _g = useState(null), hostInfo = _g[0], sethostInfo = _g[1];
    var _h = useState(1), numberOfGroups = _h[0], setNumberOfGroups = _h[1];
    var _j = useState(null), currentToken = _j[0], setcurrentToken = _j[1];
    var _k = useState(null), groupData = _k[0], setGroupData = _k[1];
    var _l = useState(null), tokenInfo = _l[0], setTokenInfo = _l[1];
    var _m = useState(""), selectedVideo = _m[0], setselectedVideo = _m[1];
    var _o = useState({}), attendeeRecord = _o[0], setAttendeeRecord = _o[1];
    var _p = useState(ViewEnum.Gallery), view = _p[0], setView = _p[1];
    var _q = useState(null), joiningTime = _q[0], setjoiningTime = _q[1];
    var _r = useState(false), showDrawer = _r[0], setShowDrawer = _r[1];
    var _s = useState("dark"), theme = _s[0], setTheme = _s[1];
    var _t = useState(true), appLeaveRoute = _t[0], setappLeaveRoute = _t[1];
    var _u = useState(null), spotlightId = _u[0], setSpotlightId = _u[1];
    var _v = useState(false), primaryLoader = _v[0], setPrimaryLoader = _v[1];
    var _w = useState(false), seacondaryLoader = _w[0], setSeacondaryLoader = _w[1];
    var _x = useState(false), isVideoEnabledLocal = _x[0], setisVideoEnabledLocal = _x[1];
    var _y = useState(false), isGroupCreated = _y[0], setIsGroupCreated = _y[1];
    var _z = useState(false), isSharingAllowed = _z[0], setIsSharingAllowed = _z[1];
    var _0 = useState([]), playlist = _0[0], setPlaylist = _0[1];
    var _1 = useState(false), isAppleAuthorized = _1[0], setIsAppleAuthorized = _1[1];
    var _2 = useState(false), isMusicShared = _2[0], setIsMusicShared = _2[1];
    var toggleTheme = function (value) {
        setTheme(function () { return value; });
    };
    var handleChange = function (view) {
        setView(view);
    };
    var toggleScreenShare = function (allow) {
        sethostInfo(function (prev) { return (__assign(__assign({}, prev), { MeetingInfo: __assign(__assign({}, prev.MeetingInfo), { can_share: allow }) })); });
    };
    var setAppMeetingInfo = function (id, name, regionValue) {
        setRegion(regionValue);
        setMeeting(id);
        setLocalName(name);
    };
    var setJwtToken = function (jwt) {
        setTokenInfo(jwt);
    };
    var providerValue = useMemo(function () { return ({
        meetingId: meetingId,
        localUserName: localUserName,
        theme: theme,
        region: region,
        meetingInfo: meetingInfo,
        hostInfo: hostInfo,
        currentToken: currentToken,
        selectedVideo: selectedVideo,
        numberOfGroups: numberOfGroups,
        attendeeRecord: attendeeRecord,
        view: view,
        showDrawer: showDrawer,
        joiningTime: joiningTime,
        groupData: groupData,
        appLeaveRoute: appLeaveRoute,
        spotlightId: spotlightId,
        isVideoEnabledLocal: isVideoEnabledLocal,
        meetingType: meetingType,
        tokenInfo: tokenInfo,
        isGroupCreated: isGroupCreated,
        playlist: playlist,
        isAppleAuthorized: isAppleAuthorized,
        seacondaryLoader: seacondaryLoader,
        isSharingAllowed: isSharingAllowed,
        isMusicShared: isMusicShared,
        primaryLoader: primaryLoader,
        setIsAppleAuthorized: setIsAppleAuthorized,
        setPlaylist: setPlaylist,
        setmeetingType: setmeetingType,
        setSpotlightId: setSpotlightId,
        toggleTheme: toggleTheme,
        setShowDrawer: setShowDrawer,
        setAppMeetingInfo: setAppMeetingInfo,
        setMeetingInfo: setMeetingInfo,
        sethostInfo: sethostInfo,
        setcurrentToken: setcurrentToken,
        setselectedVideo: setselectedVideo,
        setAttendeeRecord: setAttendeeRecord,
        setNumberOfGroups: setNumberOfGroups,
        handleChange: handleChange,
        setjoiningTime: setjoiningTime,
        setGroupData: setGroupData,
        setappLeaveRoute: setappLeaveRoute,
        setisVideoEnabledLocal: setisVideoEnabledLocal,
        setJwtToken: setJwtToken,
        setIsGroupCreated: setIsGroupCreated,
        toggleScreenShare: toggleScreenShare,
        setSeacondaryLoader: setSeacondaryLoader,
        setIsMusicShared: setIsMusicShared,
        setPrimaryLoader: setPrimaryLoader
    }); }, [
        meetingId,
        localUserName,
        theme,
        region,
        meetingInfo,
        hostInfo,
        currentToken,
        selectedVideo,
        numberOfGroups,
        attendeeRecord,
        view,
        showDrawer,
        joiningTime,
        groupData,
        appLeaveRoute,
        spotlightId,
        isVideoEnabledLocal,
        meetingType,
        tokenInfo,
        isGroupCreated,
        playlist,
        isAppleAuthorized,
        seacondaryLoader,
        isSharingAllowed,
        isMusicShared,
        primaryLoader,
        setIsAppleAuthorized,
        setPlaylist,
        setmeetingType,
        setSpotlightId,
        toggleTheme,
        setShowDrawer,
        setAppMeetingInfo,
        setMeetingInfo,
        sethostInfo,
        setcurrentToken,
        setselectedVideo,
        setAttendeeRecord,
        setNumberOfGroups,
        handleChange,
        setjoiningTime,
        setGroupData,
        setappLeaveRoute,
        setisVideoEnabledLocal,
        setJwtToken,
        setIsGroupCreated,
        toggleScreenShare,
        setSeacondaryLoader,
        setIsMusicShared,
        setPrimaryLoader
    ]);
    return (React.createElement(AppStateContext.Provider, { value: providerValue }, children));
}
