var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { space } from "styled-system";
import { css } from "styled-components";
export var baseStyles = function (_a) {
    var css = _a.css;
    return css ? "@media { " + css + " };" : "";
};
export var baseSpacing = space;
export var visuallyHidden = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  clip: rect(1px, 1px, 1px, 1px);\n  height: 1px;\n  width: 1px;\n  overflow: hidden;\n  position: absolute !important;\n"], ["\n  clip: rect(1px, 1px, 1px, 1px);\n  height: 1px;\n  width: 1px;\n  overflow: hidden;\n  position: absolute !important;\n"])));
export var ellipsis = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"], ["\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"])));
export var FlexEnd = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"])));
export var FlexCenter = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var FlexStart = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n"])));
export var absoluteCenter = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"], ["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"])));
export var isValidCSSHex = function (hex) {
    return /^#[0-9A-F]{6}$/i.test(hex);
};
export var hexTorgba = function (hex, alpha) {
    var _a;
    if (alpha === void 0) { alpha = 1; }
    if (!isValidCSSHex(hex)) {
        return "";
    }
    var _b = (_a = hex.match(/\w\w/g)) === null || _a === void 0 ? void 0 : _a.map(function (h) { return parseInt(h, 16); }), r = _b[0], g = _b[1], b = _b[2];
    return "rgba(" + r + ", " + g + ", " + b + ", " + (alpha || 1) + ")";
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
