export var SUITE_PEACH_AUDIO_MIXER = "SuitePeach - Audio Sharing";
export var WINDOWS_DRIVER_MICROPHONE_NAME = "SuitePeach";
export var WINDOWS_DRIVER_SPEAKER_NAME = "SuitePeach";
export var BLACK_HOLE_AUDIO_MIXER = "BlackHole";
export var DEFAULT_DEVICE = "default";
export var LOADED = "loaded";
export var MEDIA_PIPELINE = "MediaPipeline-";
export var GROUP_OPTIONS = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
];
export var GROUP_SWITCH_OPTIONS = {
    auto: {
        value: "auto",
        label: "Automatically",
    },
    manual: {
        value: "manual",
        label: "Manually",
    },
};
export var ROLES;
(function (ROLES) {
    ROLES["Moderator"] = "moderator";
    ROLES["Instructor"] = "instructor";
    ROLES["Attendee"] = "attendee";
    ROLES["Audience"] = "audience";
})(ROLES || (ROLES = {}));
