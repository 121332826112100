import React, { memo } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles } from "@material-ui/core/styles";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    });
});
var Loader = function (_a) {
    var open = _a.open;
    var classes = useStyles();
    return (React.createElement(Backdrop, { className: classes.backdrop, open: open },
        React.createElement(CircularProgress, { color: "primary" })));
};
export default memo(Loader);
