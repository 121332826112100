import React from "react";
import { StyledHeader, StyledImage, WaitingForListContainer } from "./Styled";
import { StyledDiv } from "../Styled";
import Error from "../../asserts/svg/error.svg";
var ApplicationError = function () {
    return (React.createElement(StyledDiv, null,
        React.createElement(WaitingForListContainer, null,
            React.createElement(StyledImage, { src: Error, alt: "Application Error" }),
            React.createElement(StyledHeader, null, "Please try later"))));
};
export default React.memo(ApplicationError);
