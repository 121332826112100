import { SDK_LOG_LEVELS } from "./contants";
var urlParams = new URLSearchParams(window.location.search);
var queryLogLevel = urlParams.get("logLevel");
var logLevel = SDK_LOG_LEVELS[queryLogLevel] || SDK_LOG_LEVELS.error;
var postLogConfig = {
    name: "SDK_LOGS",
    batchSize: 85,
    intervalMs: 2000,
    url: process.env.REACT_APP_API_URL + "/meeting/session/logs",
    logLevel: logLevel
};
var config = {
    enableWebAudio: false,
    logLevel: logLevel,
    postLogConfig: postLogConfig
};
export default config;
