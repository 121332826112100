import React, { useState, useMemo } from "react";
var context = React.createContext({
    errorMessage: "",
    updateErrorMessage: function (_) { },
});
export function getErrorContext() {
    return context;
}
var ErrorProvider = function (_a) {
    var children = _a.children;
    var _b = useState(""), errorMessage = _b[0], setErrorMesage = _b[1];
    var ErrorContext = getErrorContext();
    var updateErrorMessage = function (message) {
        setErrorMesage(message);
    };
    var providerValue = useMemo(function () { return ({
        errorMessage: errorMessage,
        updateErrorMessage: updateErrorMessage,
    }); }, [errorMessage, updateErrorMessage]);
    return (React.createElement(ErrorContext.Provider, { value: providerValue }, children));
};
export default ErrorProvider;
