export var Platforms = {
    WINDOWS: "WINDOWS",
    MAC: "MAC",
    LINUX: "LINUX",
    SUN: "SUN",
    OPENBSD: "OPENBSD",
    ANDROID: "ANDROID",
    AIX: "AIX",
};
var platformsNames = {
    win32: Platforms.WINDOWS,
    darwin: Platforms.MAC,
    linux: Platforms.LINUX,
    sunos: Platforms.SUN,
    openbsd: Platforms.OPENBSD,
    android: Platforms.ANDROID,
    aix: Platforms.AIX,
};
var getOS = function () {
    return platformsNames[window.process.platform];
};
export default getOS;
